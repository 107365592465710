<template>
  <div>
    <el-popover
      style="position: absolute; top: 30%; left: 40%"
      placement="top"
      trigger="manual"
      v-model="value"
    >
      <div class="sliding-pictures">
        <div class="vimg">
          <canvas id="markImg"></canvas>
          <canvas id="bgImg"></canvas>
        </div>
        <div class="slider">
          <div class="track" :class="{ pintuTrue: puzzle }">
            {{ tips }}
          </div>
          <div class="button el-icon-s-grid" @mousedown.prevent="drag"></div>
        </div>
        <div class="operation">
          <span
            title="关闭验证码"
            @click="close(0)"
            class="el-icon-circle-close"
          ></span>
          <span
            title="刷新验证码"
            @click="refresh"
            class="el-icon-refresh-left"
          ></span>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import md5 from "js-md5";
export default {
  data() {
    return {
      slider: {
        mx: 0,
        bx: 0,
      },
      puzzle: false,
      tips: "拖动滑块完成上方拼图",
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    catcha: {
      type: Object,
      default: {},
    },
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        this.draw();
        this.puzzle = false;
      },
    },
    "catcha.verifyToken": {
      deep: true,
      handler(value) {
        this.draw();
        this.puzzle = false;
      },
    },
  },
  methods: {
    //鼠标按下
    drag(e) {
      let dom = e.target; //dom元素
      let slider = document.querySelector("#markImg"); //滑块dom
      const downCoordinate = { x: e.x, y: e.y };
      //x轴数据
      let x = 0;
      const move = (moveEV) => {
        x = moveEV.x - downCoordinate.x;
        // 可移动范围
        if (x >= 280 || x <= 0) {
          return false;
        }
        dom.style.left = x + "px";
        slider.style.left = x + "px";
      };
      const up = (e) => {
        document.removeEventListener("mousemove", move);
        document.removeEventListener("mouseup", up);
        // 采用点击距离和最终离开距离计算滑动距离
        let endstr = this.catcha.verifyToken.split("_")[1];
        let movex = (e.x - downCoordinate.x) / 1.25; //图片放大倍数
        dom.style.left = "";
        slider.style.left = "";
        for (let index = -15; index < 20; index++) {
          let offset = Math.ceil(movex + index);
          console.log('offset',offset);
          
          let str = `verify_code_${endstr}_${offset}`;
          let tk = `${md5(str)}_${endstr}`;
          if (tk == this.catcha.verifyToken) {
            this.puzzle = true;
            this.close(offset);
            this.tips = "验证成功";
            this.refresh();
          } else {
            this.tips = "验证失败，请重试";
            this.puzzle = false;
            this.draw();
          }
        }
      };
      document.addEventListener("mousemove", move);
      document.addEventListener("mouseup", up);
    },
    //拼图验证码初始化
    draw() {
      let mainDom = document.querySelector("#bgImg");
      let bg = mainDom.getContext("2d");
      let width = mainDom.width;
      let height = mainDom.height;
      let blockDom = document.querySelector("#markImg");
      let block = blockDom.getContext("2d");
      this.tips = "拖动滑块完成上方拼图";
      //
      blockDom.height = height;
      mainDom.height = height;
      let img = document.createElement("img");
      img.src = this.catcha.bgImg;
      img.onload = () => {
        bg.drawImage(img, 0, 0, width, height);
      };
      let img1 = document.createElement("img");
      img1.src = this.catcha.markImg;
      let h = this.catcha.height;
      img1.onload = () => {
        block.drawImage(img1, 0, h, 80, 70);
      };
    },
    close(e) {
      this.$emit("close", e);
    },
    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.sliding-pictures {
  width: 300px;
  .vimg {
    width: 100%;
    height: 187px;
    #bgImg,
    #markImg {
      padding: 7px 7px 0 7px;
      width: inherit;
      height: inherit;
    }
    #bgImg {
      //display: none;
    }
    #markImg {
      position: absolute;
      z-index: 4000;
    }
  }
  .slider {
    width: 100%;
    height: 65px;
    border-bottom: #c7c9d0 1px solid;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .track {
      margin: 7px;
      width: 300px;
      height: 38px;
      background: rgba(28, 136, 188, 0.5);
      border-radius: 25px;
      font-size: 14px;
      line-height: 38px;
      padding-right: 15px;
      padding-left: 70px;
    }
    .pintuTrue {
      background: #67c23a;
      color: #ffffff;
    }
    .button {
      position: absolute;
      width: 50px;
      height: 50px;
      line-height: 48px;
      background: #ffffff;
      box-shadow: #b9bdc8 0 0 3px;
      border-radius: 50%;
      left: 7px;
      text-align: center;
      font-size: 28px;
      color: #3e5d8b;
      &:hover {
        color: #2181bd;
      }
    }
  }
  .operation {
    width: 100%;
    height: 40px;
    > span {
      color: #9fa3ac;
      display: inline-block;
      width: 40px;
      font-size: 25px;
      line-height: 40px;
      text-align: center;
      &:hover {
        background: #e2e8f5;
      }
    }
  }
}
</style>