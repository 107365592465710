
















































import { Component, Vue } from "vue-property-decorator";
import LsFooter from "@/components/layout/footer.vue";
import verification from "./verification.vue";
import { apiLogin, apiCaptcha, apiverificationCaptcha, apiconfig } from "@/api/app";
import { Action } from "vuex-class";
import cache from "@/utils/cache";
@Component({
    components: {
        LsFooter,
        verification
    },
})
export default class Login extends Vue {
    $refs!: { form: any };
    @Action("getPermission") getPermission!: () => void;
    rememberAccount = false;
    accountObj = {
        account: "",
        password: "",
        verification_code: "",
    };
    catcha = {
        bgImg: "",
        markImg: "",
        height: 0,
        verifyToken: "",
    }
    rules: any = {
        account: [
            {
                required: true,
                message: "请输入账号",
                trigger: ["blur", "change"],
            },
        ],
        password: [
            {
                required: true,
                message: "请输入密码",
                trigger: ["blur", "change"],
            },
        ],
    };
    ca = "获取验证码";
    enable = 2;
    visible = false;
    loadingLogin = false;
    flag = false;
    flag2 = false;

    get config() {
        return this.$store.getters.config;
    }
    // S Methods
    // 点击登录
    handleLogin() {
        this.$refs.form.validate((valid: boolean): void => {
            if (!valid) return;
            cache.set("remember_account", {
                remember: this.rememberAccount,
                account: this.accountObj.account,
            });
            // this.visible = true;

            this.login();
        });
    }
    confrim(e: any) {
        if (e) {
            this.login();
        }
        this.visible = false;
    }

    ongetcode() {
        if (this.flag) return;
        if (this.flag2) return;
        this.flag2 = true;
        //获取验证码
        apiverificationCaptcha({ ...this.accountObj }).then((res) => {
            this.timelog(60);

        }).catch((err) => {
            this.flag2 = false;
        })
      
    }

    timelog(time: number) {
        if (time == 0) {
            this.flag = false;
            this.ca = "获取验证码";
            return;
        }
        this.flag = true;
        this.ca = `请于${time}秒后重试`;
        setTimeout(() => {
            this.timelog(time - 1);
        }, 1000);
    }

    getJigsawCaptcha() {
        apiCaptcha().then((res) => {
            this.catcha = res;
            this.$forceUpdate()
        });
    }

    getenable() {
        apiconfig().then((res) => {
            this.enable = res.platform_login_sms_verify;
        });
    }

    //登录
    login() {
        this.loadingLogin = true;
        const { account, password, verification_code } = this.accountObj;
        if (this.enable == 1) {
            if (verification_code == '') {
                this.$message.error('请输入验证码');
                this.loadingLogin = false;
                return
            }

        }
        apiLogin({
            account,
            password,
            verification_code,
            terminal: 1,
        })
            .then((data) => {
                this.$store.commit("setUserInfo", data);
                // return this.getPermission();
            })
            .then(async () => {
                const {
                    query: { redirect },
                } = this.$route;
                await this.$store.dispatch("getPermission");
                const menu = this.$store.getters.menu;
                const _path = menu[0].redirect
                    ? menu[0].redirect
                    : menu[0].path;
                const path = typeof redirect === "string" ? redirect : _path;
                this.$router.replace(path);
            })
            .catch(() => {
                this.loadingLogin = false;
            });
    }
    created() {
        // this.getJigsawCaptcha();
        this.getenable();
        const value = cache.get("remember_account");
        if (value.remember) {
            this.rememberAccount = value.remember;
            this.accountObj.account = value.account;
        }
    }
}
